import styled from "styled-components";
import Select from "antd/lib/select";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFilters } from "shared/hooks/filters.hooks";
import { useRegion } from "../../hooks/region.hooks";
import { regionsOptions } from "../../store/regions/regions.selector";

const StyledSelect = styled(Select)`
  min-width: 200px;
  max-width: 100%;
`;

const SelectMapRegion = () => {
  const options = useSelector(regionsOptions);
  const navigate = useNavigate();
  const [{ search }] = useFilters();
  const region = useRegion();

  const handleChange = useCallback(
    (value: unknown) => {
      if (value) {
        navigate(`/${value}?${search.toString()}`);
      } else {
        navigate(`/?${search.toString()}`);
      }
    },
    [navigate, search]
  );

  return (
    <StyledSelect
      allowClear
      placeholder="Выберите регион"
      options={options}
      value={region?.res_code}
      onChange={handleChange}
    />
  );
};

export default React.memo(SelectMapRegion);

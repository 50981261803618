import styled from "styled-components";
import { device } from "shared/util/mediaQueries";

export const RatingContainer = styled.div`
  margin-bottom: 2rem;
`;

export const RatingTitle = styled.div`
  font-weight: 700;
  color: #696969;
  text-align: center;
  width: 100%;
  @media ${device.laptopM} {
    min-width: 400px;
  }
`;

export const RatingFilter = styled.div`
  text-align: center;
`;

export const RatingFilterItem = styled.span<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? "#008ffb" : "inherit")};
  text-decoration: ${({ isActive }) => (isActive ? "underline" : "none")};
  cursor: pointer;
  margin-left: 8px;
`;

export const RatingList = styled.div`
  margin-bottom: 1rem;
`;

export const RatingLegend = styled.div`
  text-align: center;
`;

export const RatingLegendItem = styled.div<{ color: string }>`
  &::before {
    display: inline-block;
    content: "";
    width: 10px;
    height: 10px;
    margin-right: 5px;
    background-color: ${({ color }) => color};
    border-radius: 2px;
  }
`;

export const RatingItemName = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  font-size: 12px;
  color: #696969;
  transition: color linear 0.1s;
`;

export const RatingItemChart = styled.div`
  transition: margin-left linear 0.2s;
`;

export const RatingItemChartWrapper = styled.div`
  display: flex;
  border-top: 1px solid #e0e0e0;
  padding-top: 2px;
`;

export const RatingItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 130px 1fr;
  gap: 20px;
  margin-bottom: 2px;
  margin-right: 1rem;
  cursor: pointer;
  &:hover {
    ${RatingItemName} {
      color: #1890ff;
    }
    ${RatingItemChart} {
      margin-left: 5px;
    }
  }
`;

export const RatingItemChartBar = styled.div<{ width: number; color: string }>`
  width: ${({ width }) => `${width}%`};
  min-width: fit-content;
  background-color: ${({ color }) => color};
  color: #fff;
  text-align: center;
  padding: 2px 5px;
  font-size: 11px;
  transition: width linear 0.2s;
`;

export const RatingNone = styled.h4`
  text-align: center;
`;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { YMaps } from "react-yandex-maps";
import Spin from "antd/lib/spin";
import Layout from "antd/lib/layout";

import { fetchAllData } from "store/app/app.reducer";
import { selectLoading } from "store/app/app.selectors";

import Header from "shared/components/Header/Header";
import SplitScreen from "shared/components/SplitScreen";
import Sidebar from "components/Sidebar/Sidebar";
import Map from "components/Map/Map";

function App() {
  const dispatch = useDispatch();
  const { loading, label } = useSelector(selectLoading);

  useEffect(() => {
    dispatch(fetchAllData());
  }, [dispatch]);

  return (
    <Spin tip={label} spinning={loading}>
      <Layout>
        <BrowserRouter>
          <YMaps version="2.1.77" preload>
            <Header />
            <SplitScreen>
              <Map />
              <Sidebar />
            </SplitScreen>
          </YMaps>
        </BrowserRouter>
      </Layout>
    </Spin>
  );
}

export default React.memo(App);
